import Logo from "@/components/Logo";
import { Box, Button, Container, Divider, Paper, Stack } from "@mui/material";
import type { GetServerSidePropsContext, InferGetServerSidePropsType } from "next";
import { getServerSession } from "next-auth/next";
import { getCsrfToken, getProviders, signIn } from "next-auth/react";
import { authOptions } from "../api/auth/[...nextauth]";

export default function SignIn(props: InferGetServerSidePropsType<typeof getServerSideProps>) {

    const { providers, csrfToken } = props
    
    return (
        <Container>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <Box width={290} height={400}>
                    <Paper elevation={2} style={{ padding: 20 }}>
                        <Logo type="icon" mb={1} />
                        <Divider />
                        <Stack spacing={1} mt={2} pl={3} pr={3}>
                            {
                                Object.values(providers).map((provider) => {
                                    if (provider.id == 'email')
                                        return null;

                                    let name = (provider.id == 'azure-ad') ? 'Azure' : provider.name;
                                    let iconSize = 20;
                                    return <Button size="medium" color="secondary" key={provider.name} onClick={() => { signIn(provider.id) }} style={{ position: "relative", justifyContent: "flex-start" }}>
                                        <Box component={"span"} style={{ position: "absolute", width: iconSize, height: iconSize, left: 8, top: '50%', transform: "translateY(-50%)", backgroundImage: `url(/img/icons/${provider.id}.svg);`, backgroundSize: "contain" }}></Box>
                                        <Box component={"span"} style={{ paddingLeft: 25 }}>Sign in with {name}</Box>
                                    </Button>
                                })
                            }
                        </Stack>
                    </Paper>
                </Box>
            </Box >
        </Container >
    )
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
    const session = await getServerSession(context.req, context.res, authOptions);
    const csrfToken = await getCsrfToken(context)

    // If the user is already logged in, redirect.
    // Note: Make sure not to redirect to the same page
    // To avoid an infinite loop!
    if (session)
        return { redirect: { destination: "/" } };

    const providers = await getProviders();
    return {
        props: { providers: providers ?? [], csrfToken: csrfToken },
    }
}