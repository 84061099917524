import { Box, BoxProps } from "@mui/material";

type BoxWithStateProps = BoxProps & {
    type: 'icon' | 'full'
}

export default function Logo(props: BoxWithStateProps) {
    return (
        <>
            {props.type == 'icon' &&
                <Box textAlign={'center'} {...props}>
                    <img alt="Bravo Logo" src="/img/logo-icon.png" width={64} height={64} />
                </Box>
            }
            {props.type == 'full' &&
                <Box  {...props}>
                    <img alt="Bravo Logo" src="/img/logo-icon.png" style={{ maxHeight: 30 }} />
                </Box>
            }
        </>
    )
}